import { createRouter, createWebHistory } from 'vue-router';
import Events from '../views/Events.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      fi: 'Etusivu',
      categoryId: 1,
      metaTitle: 'Tapahtumalauta - Kaikki tapahtumat',
      metaDesc: 'Kaikki tapahtumat',
    },
    component: Events,
  },

  //
  // Categories
  //

  {
    path: '/urheilu',
    name: 'sport',
    meta: {
      fi: 'Urheilu',
      categoryId: 2,
      metaTitle: 'Urheilu',
      metaDesc: 'Urheilutapahtumat',
    },
    component: Events,
  },
  {
    path: '/lapset-ja-perheet',
    name: 'family',
    meta: {
      fi: 'Lapset ja perheet',
      categoryId: 3,
      metaTitle: 'Lapsset ja perheet',
      metaDesc: 'Lapsille ja perheille suunnatut tapahtumat',
    },
    component: Events,
  },
  {
    path: '/kulttuuri',
    name: 'cultur',
    meta: {
      fi: 'Kulttuuri',
      categoryId: 4,
      metaTitle: 'Kulttuuri',
      metaDesc: 'Kulttuuritapahtumat',
    },
    component: Events,
  },
  {
    path: '/hyvinvointi',
    name: 'wellbeing',
    meta: {
      fi: 'Hyvinvointi',
      categoryId: 5,
      metaTitle: 'Hyvinvointi',
      metaDesc: 'Hvinvointiin liittyvät tapahtumat',
    },
    component: Events,
  },

  //
  // Single event
  //
  {
    path: '/tapahtuma/:slug',
    name: 'event',
    meta: {
      fi: 'Tapahtuma',
    },
    component: () =>
      import(/* webpackChunkName: "new-event" */ '../views/About.vue'),
  },

  //
  // New event
  //

  {
    path: '/ilmoita-tapahtuma',
    name: 'newEvent',
    meta: { fi: 'Ilmoita tapahtuma' },
    component: () =>
      import(/* webpackChunkName: "new-event" */ '../views/About.vue'),
  },

  //
  // Profile
  //

  {
    path: '/omat-tiedot',
    name: 'profile',
    meta: { fi: 'Omat tiedot' },
    component: () =>
      import(/* webpackChunkName: "new-event" */ '../views/About.vue'),
  },

  //
  // Auth
  //

  {
    path: '/kirjautuminen',
    name: 'login',
    meta: { fi: 'Kirjautuminen' },
    component: () =>
      import(/* webpackChunkName: "new-event" */ '../views/auth/Login.vue'),
  },
  {
    path: '/uloskirjatuminen',
    name: 'logout',
    meta: { fi: 'Uloskirjautuminen' },
    component: () =>
      import(/* webpackChunkName: "new-event" */ '../views/auth/Logout.vue'),
  },
  {
    path: '/rekisteroityminen',
    name: 'register',
    meta: { fi: 'Rekisteröityminen' },
    component: () =>
      import(/* webpackChunkName: "new-event" */ '../views/auth/Register.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: 'active-link-class',
});

// This callback runs before every route change, including on page load.
/*router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll('[data-vue-router-controlled]')
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});*/

export default router;
