import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import axios from './axios/Api';
import VueAxios from 'vue-axios';
import { store } from './store';

import './assets/styles/style.css';
import './assets/styles/tailwind.css';

//createApp(App).use(router, VueAxios, axios, store).mount('#app');

const app = createApp(App);
app.use(router);
app.use(VueAxios, axios);
app.use(store);
app.mount('#app');
