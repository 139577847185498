import { createStore } from 'vuex';
import User from '../axios/User';

// Create a new store instance.
export const store = createStore({
  state() {
    return {
      user: {
        signedIn: localStorage.getItem('s') || false,
        role: localStorage.getItem('r') || 0,
        username: localStorage.getItem('username') || '',
      },
    };
  },
  getters: {
    getSignInStatus(state) {
      return state.user.signedIn;
    },
    getRole(state) {
      return state.user.role;
    },
    getUsername(state) {
      return state.user.username;
    },
  },

  mutations: {
    LOGIN(state, user) {
      state.user.signedIn = true;
      state.user.role = user.role;
      state.user.username = user.username;
    },
    LOGOUT(state) {
      state.user.signedIn = false;
      state.user.role = 0;
      state.user.username = '';
    },
  },
  actions: {
    login({ commit }, form) {
      try {
        return User.login(form).then((result) => {
          const user = result.data;
          localStorage.setItem('s', true);
          localStorage.setItem('r', user.role);
          localStorage.setItem('username', user.username);
          commit('LOGIN', user);
        });
      } catch (error) {
        return error;
      }
    },
    logout({ commit }) {
      localStorage.clear();
      commit('LOGOUT');
    },
  },
});

//export default store;
