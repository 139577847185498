<template>
  <div class="flex mx-auto text-textcolor">
    <nav
      v-if="windowSize > 499"
      class="flex-grow items-end h-screen sticky top-0"
    >
      <div class="flex flex-col items-end h-full">
        <div
          class="flex flex-col justify-between w-16 lg:w-48 h-full overflow-auto"
        >
          <div>
            <router-link
              v-for="category in categories"
              :key="category.id"
              :to="{ name: category.to }"
              class="flex flex-row flex-grow pl-5 pr-5 pt-4 pb-4 sm:p-5 transition-colors duration-200 ease-in-out clicked:bg-white"
            >
              <svg class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                <path :d="category.icon" />
              </svg>
              <span v-if="windowSize > 1023" class="pl-4 text-lg font-bold">{{
                category.fi
              }}</span>
            </router-link>
          </div>
          <div class="mb-24">
            <div v-if="signedIn">
              <router-link
                :to="{ name: 'home' }"
                class="flex flex-row flex-grow pl-5 pr-5 pt-4 pb-4 sm:p-5 transition-colors duration-200 ease-in-out"
              >
                <svg class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                  <path :d="icons.mdiShieldAccount" />
                </svg>
                <span v-if="windowSize > 1023" class="pl-4 text-lg font-bold">
                  Profiili
                </span>
              </router-link>
              <router-link
                :to="{ name: 'logout' }"
                class="flex flex-row flex-grow pl-5 pr-5 pt-4 pb-4 sm:p-5 transition-colors duration-200 ease-in-out"
              >
                <svg class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                  <path :d="icons.mdiLogout" />
                </svg>
                <span v-if="windowSize > 1023" class="pl-4">
                  Kirjaudu ulos
                </span>
              </router-link>
            </div>
            <div v-else>
              <router-link
                :to="{ name: 'login' }"
                class="flex flex-row flex-grow pl-5 pr-5 pt-4 pb-4 sm:p-5 transition-colors duration-200 ease-in-out"
              >
                <svg class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                  <path :d="icons.mdiLogin" />
                </svg>
                <span v-if="windowSize > 1023" class="pl-4 text-lg font-bold">
                  Kirjaudu
                </span>
              </router-link>
              <router-link
                :to="{ name: 'register' }"
                class="flex flex-row flex-grow pl-5 pr-5 pt-4 pb-4 sm:p-5 transition-colors duration-200 ease-in-out"
              >
                <svg class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                  <path :d="icons.mdiAccountPlusOutline" />
                </svg>
                <span v-if="windowSize > 1023" class="pl-4 text-lg font-bold">
                  Luo uusi tili
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <nav
      v-if="navPanel && windowSize < 500"
      class="z-10 fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-50"
      @click="navPanel = false"
    >
      <div
        class="flex flex-col justify-between w-2/3 h-full overflow-auto bg-gray-100"
      >
        <div>
          <router-link
            v-for="category in categories"
            :key="category.id"
            :to="{ name: category.to }"
            class="flex flex-row flex-grow p-4 transition-colors duration-200 ease-in"
          >
            <svg class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
              <path :d="category.icon" />
            </svg>
            <span class="pl-4 text-lg font-bold">{{ category.fi }}</span>
          </router-link>
        </div>
        <div class="mb-24">
          <div v-if="signedIn">
            <router-link
              :to="{ name: 'home' }"
              class="flex flex-row flex-grow p-4 transition-colors duration-200 ease-in"
            >
              <svg class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                <path :d="icons.mdiShieldAccount" />
              </svg>
              <span class="pl-4 text-lg font-bold">Profiili</span>
            </router-link>
            <div class="border-b" />
            <router-link
              :to="{ name: 'logout' }"
              class="flex flex-row flex-grow p-4 transition-colors duration-200 ease-in"
            >
              <svg class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                <path :d="icons.mdiLogout" />
              </svg>
              <span class="pl-4">Kirjaudu ulos</span>
            </router-link>
          </div>
          <div v-else>
            <router-link
              :to="{ name: 'login' }"
              class="flex flex-row flex-grow p-4 transition-colors duration-200 ease-in"
            >
              <svg class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                <path :d="icons.mdiLogin" />
              </svg>
              <span class="pl-4 text-lg font-bold">Kirjaudu sisään</span>
            </router-link>
            <router-link
              :to="{ name: 'register' }"
              class="flex flex-row flex-grow p-4 transition-colors duration-200 ease-in"
            >
              <svg class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                <path :d="icons.mdiAccountPlusOutline" />
              </svg>
              <span class="pl-4 text-lg font-bold">Luo uusi tili</span>
            </router-link>
          </div>
        </div>
      </div>
    </nav>
    <main class="flex-grow max-w-xl w-full border-l border-r">
      <div class="sticky top-0 font-bold bg-white">
        <div class="flex justify-between items-center px-2 h-12 border-b">
          <button
            v-if="windowSize < 500"
            class="border-0 pr-4"
            @click="navPanel = !navPanel"
          >
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>

          <div>{{ title }}</div>

          <router-link
            :to="{ name: 'newEvent' }"
            class="flex flex-row p-1 text-active"
          >
            <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
              <path :d="icons.mdiCalendarPlus" />
            </svg>
            <span v-if="windowSize > 450" class="pl-4 text-lg font-bold">
              Ilmoita tapahtuma
            </span>
          </router-link>
        </div>
      </div>
      <router-view />
    </main>
    <aside v-if="windowSize > 767" class="flex-grow pl-2 font-bold">
      <div class="sticky top-0">Yhteistyössä</div>
    </aside>
  </div>
</template>

<script>
import {
  mdiHome,
  mdiSoccer,
  mdiHumanMaleBoy,
  mdiDramaMasks,
  mdiLeaf,
  mdiCalendarPlus,
  mdiSortCalendarAscending,
  mdiSortNumericDescending,
  mdiAccountCircleOutline,
  mdiAccountPlusOutline,
  mdiLogin,
  mdiLogout,
  mdiShieldAccount,
  mdiMenuDown,
} from '@mdi/js';

export default {
  data: () => {
    return {
      icons: {
        mdiHome,
        mdiSoccer,
        mdiHumanMaleBoy,
        mdiDramaMasks,
        mdiLeaf,
        mdiCalendarPlus,
        mdiSortCalendarAscending,
        mdiSortNumericDescending,
        mdiAccountCircleOutline,
        mdiAccountPlusOutline,
        mdiLogin,
        mdiLogout,
        mdiShieldAccount,
        mdiMenuDown,
      },
      windowSize: window.innerWidth,
      title: 'Tapahtumalauta',
      navPanel: false,
      logOutMenu: false,
      categories: [
        { id: 0, to: 'home', icon: mdiHome, fi: 'Etusivu' },
        { id: 1, to: 'sport', icon: mdiSoccer, fi: 'Urheilu' },
        { id: 2, to: 'family', icon: mdiHumanMaleBoy, fi: 'Lapset ja perheet' },
        { id: 3, to: 'cultur', icon: mdiDramaMasks, fi: 'Kulttuuri' },
        { id: 4, to: 'wellbeing', icon: mdiLeaf, fi: 'Hyvinvointi' },
      ],
    };
  },
  computed: {
    signedIn() {
      return this.$store.state.user.signedIn;
    },
    username() {
      return this.$store.state.user.username;
    },
  },
  watch: {
    $route(to, from) {
      document.title = to.meta.fi + ' | Tapahtumalauta' || 'Tapahtumalauta';
      this.title = to.name === 'home' ? 'Tapahtumalauta' : to.meta.fi;
    },
  },
  created() {
    window.addEventListener('resize', () => {
      this.windowSize = window.innerWidth;
    });
  },
};
</script>
