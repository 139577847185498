import Api from './Api';
import Csrf from './Csrf';

export default {
  async register(form) {
    return Api.post('/register', form);
  },

  async login(form) {
    await Csrf.getCsrfCookie();
    return Api.post('/login', form);
  },

  async logout() {
    return Api.post('/logout');
  },
};
