<template>
  <article class="flex flex-col m-2 p-2 border-b-4">
    <div class="flex flex-row justify-between">
      <div class="flex flex-row fill-current text-gray-800">
        <svg
          class="h-6 w-6 flex-grow"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path :d="icon" />
        </svg>
      </div>
      <div class="text-xs"><slot name="user">Ilmoittaja</slot></div>
    </div>
    <div class="pl-4 py-2 text-xl">
      <slot name="title">Otsikko</slot>
    </div>
    <div class="pl-4">
      <div class="flex flex-row pt-1 pb-1">
        <span class="pr-2">
          <svg
            class="h-6 w-6 fill-current text-gray-800"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path :d="icons.mdiCalendar" />
          </svg>
        </span>
        <slot name="date">Päivä</slot>
        <span class="pl-4 pr-2">
          <svg
            class="h-6 w-6 fill-current text-gray-800"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path :d="icons.mdiClockOutline" />
          </svg>
        </span>
        <slot name="time">Aika</slot>
      </div>
      <div class="pt-1 pb-1">
        <a
          class="flex flex-row"
          :href="locationUrl"
          target="_blank"
          rel="noopener,noreferrer"
        >
          <svg
            v-if="locationType === 'locale'"
            class="h-6 w-6 fill-current text-gray-800"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path :d="icons.mdiGoogleMaps" />
          </svg>
          <svg
            v-else
            class="h-6 w-6 fill-current text-gray-800"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path :d="icons.mdiWeb" />
          </svg>
          <div v-if="locationType === 'locale'" class="pl-2">
            {{ location }}
          </div>
          <div v-else class="pl-2 tracking-wide">Osallistu etänä tästä</div>
        </a>
      </div>
      <div class="flex flex-row pt-1 pb-1">
        <svg
          class="mr-2 h-6 w-6 fill-current text-gray-800"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path :d="icons.mdiTicket" />
        </svg>
        <slot name="price">Hinta</slot>
      </div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
        facilisis sapien, eget tempor nunc. Nulla gravida elit non lorem
        venenatis, a tempus ligula laoreet. Nam venenatis feugiat lectus, eu
        blandit nunc dapibus at.
      </p>
      <div class="object-contain">
        <img
          class="mx-auto"
          src="http://d2i3zifwwcutsb.cloudfront.net/images/nod.jpg?d=600x600"
          alt="tapahtuman kuva"
        />
      </div>
    </div>
  </article>
</template>

<script>
import {
  mdiCalendar,
  mdiClockOutline,
  mdiTicket,
  mdiGoogleMaps,
  mdiWeb,
} from '@mdi/js';
export default {
  name: 'EventBox',
  props: {
    icon: {
      type: String,
      default: '',
    },
    location: {
      type: String,
      default: '',
    },
    locationType: {
      type: String,
      default: '',
    },
  },
  data: () => {
    return {
      icons: {
        mdiCalendar,
        mdiClockOutline,
        mdiTicket,
        mdiGoogleMaps,
        mdiWeb,
      },
      locationUrl: '',
    };
  },
  created() {
    if (this.locationType === 'locale') {
      this.locationUrl =
        'https://www.google.com/maps/search/?api=1&query=' +
        encodeURI(this.location);
    } else {
      this.locationUrl = encodeURI(this.location);
    }
  },
};
</script>
