<template>
  <div class="flex justify-around items-center h-12 px-2 border-b">
    <button class="flex flex-row border-0 pr-4 text-gray-700">
      <svg
        class="h-6 w-6 fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path :d="icons.mdiSortCalendarAscending" />
      </svg>
      <span class="pl-2">Tulossa</span>
    </button>
    <button class="flex flex-row border-0 pr-4 text-gray-700">
      <svg
        class="h-6 w-6 fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path :d="icons.mdiSortNumericDescending" />
      </svg>
      <span class="pl-2">Uusimmat</span>
    </button>
  </div>
  <div>
    <event-box
      :icon="icons.mdiDramaMasks"
      :location="'Vuolletie 15, 84540 Ylivieska'"
      :location-type="'locale'"
    >
      <template #title
        >Esimerkkitapahtuma 1 - Täyteteksti mikä menee usealla riville
        toivottavasti</template
      >
      <template #date>1.11.2020</template>
      <template #time>16:30</template>
      <template #price>Ilmainen</template>
    </event-box>
    <event-box
      :icon="icons.mdiSoccer"
      :location="'https://vsaukonoja.com'"
      :location-type="'web'"
    >
      <template #title>Esimerkkitapahtuma 2</template>
      <template #date>4.11.2020</template>
      <template #time>21:30</template>
      <template #price>5€</template>
    </event-box>
    <event-box
      :icon="icons.mdiLeaf"
      :location="'Vuolletie 15, 84540 Ylivieska'"
      :location-type="'locale'"
    >
      <template #title>Esimerkkitapahtuma 3</template>
      <template #date>11.11.2020</template>
      <template #time>8:00</template>
      <template #price>Aikuiset 5€, lapset ilmaiseksi</template>
    </event-box>
  </div>
</template>

<script>
import Event from '@/axios/Event';

import {
  mdiSortCalendarAscending,
  mdiSortNumericDescending,
  mdiSoccer,
  mdiHumanMaleBoy,
  mdiDramaMasks,
  mdiLeaf,
} from '@mdi/js';

import EventBox from '@/components/EventBox';

export default {
  name: 'Events',
  components: {
    EventBox,
  },
  data: () => {
    return {
      metaTitle: 'Tapahtumat',
      metaDesc: 'Kaikki tapahtumat',
      icons: {
        mdiSortCalendarAscending,
        mdiSortNumericDescending,
        mdiSoccer,
        mdiHumanMaleBoy,
        mdiDramaMasks,
        mdiLeaf,
      },
      categoryId: 0,
      order: 'latest',
      events: {},
    };
  },
  watch: {
    $route() {
      this.categoryId = this.$route.meta.categoryId;
      this.metaTitle = this.$route.meta.metaTitle;
      this.metaDesc = this.$route.meta.metaDesc;
    },
  },
  mounted() {
    this.getEvents(1, 'default');
  },
  methods: {
    async getEvents(categoryId, order) {
      try {
        await Event.getEvents(categoryId, order).then(
          (response) => (this.events = response.data)
        );
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        this.errors = error.response.data.errors;
        this.alert = true;
      }
    },
  },
  /**
   * Waiting vue 3 support 
  
  metaInfo() {
    return {
      meta: [
        { vmid: 'title', name: 'title', content: this.metaTitle },
        { vmid: 'description', name: 'description', content: this.metaDesc },
      ],
    };
  },
  */
};
</script>
